import { Suspense, lazy } from "preact/compat";
import { getParameter } from "./utils";

import './style';

import thumbEs from "./assets/thumb-es.png";
import thumbEn from "./assets/thumb-en.png";

const VideoPlayer = lazy(() => import('./components/VideoPlayer'));
const vPreloader = (
  <div class="vloader">
    <div class="ldual"></div>
  </div>
);

const getLangParameter = () => {
	const loct = window?.location;
	if(!loct)
		return;

	let search = loct.search;
	return getParameter("lang", search);
}

export default function App() {
	const langParam = getLangParameter();
	const spanish = langParam == "es";

	return (
		<div className="video">
			<div className="video_container">
				<Suspense fallback={vPreloader}>
					<VideoPlayer
						poster={spanish ? thumbEs : thumbEn}
						src={/*`https://stddata.writelytic.com/${spanish ? "es": "en"}/master.m3u8`*/
							spanish ?
							"https://stddata.writelytic.com/es/master.m3u8"
							:
							"https://stdv2data.writelytic.com/en/master.m3u8"
						}
					/>
				</Suspense>
			</div>
		</div>
	);
}
